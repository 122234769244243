<!--
 * @Description: 事故上报
 * @Author: luocheng
 * @Date: 2021-10-26 17:19:07
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-15 17:14:50
-->
<template>
	<div class="accident-report">
		<AccidentFilter
			@modifyAccident="toModify"
			@search="onSearch"
      :userList="userList"
		></AccidentFilter>
		<article class="report-main">
			<ReportContent
				:searchData="searchData"
				@modifyAccident="toModify"
			></ReportContent>
		</article>
		<!-- 弹窗 -->
		<ReportDialog
			v-if="showDialog"
			v-model="showDialog"
			:modifyConfig="modifyConfig"
			@updateList="updateList"
      :userList="userList"
      :leaderList="leaderList"
		></ReportDialog>
	</div>
</template>

<script>
import AccidentFilter from './components/AccidentFilter';
import ReportContent from './components/ReportContent';
import ReportDialog from './components/ReportDialog';
import { dataInterface } from '@/apis/data/index';

export default {
	name: 'AccidentReport',
	components: {
		AccidentFilter,
		ReportContent,
		ReportDialog
	},
	data() {
		return {
			// 弹窗
			showDialog: false,
			// 编辑配置
			modifyConfig: {},
			// 搜索数据
			searchData: {},
			// 架构类型 1 集团 2 公司  3 项目
			archiType: 1,
			userList: [],
      leaderList: []
		};
	},
	created() {
    // 抄送人 监理
		this.getSupervision();
    // 领导
    this.getLeaders();
	},
	methods: {
		/**
		 * @desc: 搜索
		 * @param {Object} data 搜索配置
		 * @return {*}
		 */
		onSearch(data) {
			this.searchData = data || {};
		},
		/**
		 * @desc: 打开编辑弹窗
		 * @param {Object} data 配置
		 */
		toModify(data) {
			this.showDialog = true;
			this.modifyConfig = data;
		},
		/**
		 * @desc: 更新数据
		 */
		updateList() {},
		/**
		 * @desc: 获取监理
		 */
		getSupervision() {
			const archiInfo = this.$GetTargetArchi();
      // 此处如何修改
			const project_id = archiInfo.archiType === 'project' ? archiInfo.id : archiInfo.id;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object617117a3ec2ce',
				view_uuid: 'view61793d2a8e4f5',
				transcode: 0,
				project_id: project_id.toString().split('-')[1],
				type: 2 // 协作单位
			})
				.then((res) => {
					if (!res || res.status !== 200) {
						return false;
					}
					const archiData = res.data.data || [];
					this.getUserFromArr(archiData);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		/**
		 * @desc: 从放回数据中拉去人员
		 * @param {Array} arr
		 * @return {Array} userList
		 */
		getUserFromArr(arr) {
			if (!arr || !Array.isArray(arr) || !arr.length) {
				return [];
			}
			this.userList = [];
      let result = [];
			arr.forEach((ele) => {
        const parentObj = JSON.parse(JSON.stringify(ele))
        delete parentObj.sections;
				if (ele && ele.sections) {
					ele.sections.forEach((section) => {
            const sectionObj = JSON.parse(JSON.stringify(section))
            delete sectionObj.users;
						if (section.users && Array.isArray(section.users)) {
              section.users.forEach(user => {
                result.push({
                  project_id: sectionObj.project_id || parentObj.project_id || '',
                  company_id: sectionObj.company_id || '',
                  user_id: user.user_id || '',
                  name: user.name || '',
                  user_table: user.user_table || ''
                })
              })
						}
					});
				}
			});
      // 去重
      result.forEach(ele => {
        if (!this.userList.find(item => item.user_id === ele.user_id)) {
          this.userList.push(ele);
        }
      });
      // console.log(this.userList, 'userListuserList')
		},

		/**
		 * @desc: 获取领导
		 */
		getLeaders() {
			const archiInfo = this.$GetTargetArchi();
			const company_id = archiInfo.archiType === 'project' ? archiInfo.company_id : archiInfo.id;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object6170c2310014e',
				view_uuid: 'view61762340488fb',
				transcode: 0,
				company_id,
				type: 1
			}).then(res => {
				if (!res || res.status !== 200) {
					return false;
				}
				const archiData = res.data.data || [];
				this.leaderList = [];
				const result = [];
				for (let i = 0; i < archiData.length; i++) {
					const archiItem = JSON.parse(JSON.stringify(archiData[i]));
					if (archiData[i] && archiData[i].users && Array.isArray(archiData[i].users)) {
						const users = archiData[i].users || [];
						delete archiItem.users;
						users.forEach(user => {
							result.push({
								company_id: archiItem.company_id || '',
								parent_id: archiItem.parent_id || '',
								user_id: user.user_id || '',
								name: user.name || '',
								user_table: user.user_table || ''
							})
						});
					}
				}
				// 去重
				result.forEach(ele => {
					if (!this.leaderList.find(item => item.user_id === ele.user_id)) {
						this.leaderList.push(ele);
					}
				});
			}).catch(err => {
				console.log(err);
			})
		},
	}
};
</script>

<style lang="less" scoped>
.accident-report {
  height: calc(100vh - 130px);
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 15px;
	.report-main {
		flex: 1;
		width: 100%;
	}
}
</style>