<!--
 * @Description: 数据上报弹窗
 * @Author: luocheng
 * @Date: 2021-10-26 18:28:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-12 10:52:42
-->
<template>
	<div class="report-dialog">
		<el-dialog
			:title="dialogTitle"
			:visible.sync="value"
			modal-append-to-body
			append-to-body
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:destroy-on-close="true"
			width="500px"
		>
			<article class="modify-content">
				<CommonTitle :title="'基本信息'"></CommonTitle>
				<el-form
					:model="modifyForm"
					ref="modifyForm"
					:rules="rules"
					label-width="100px"
				>
					<el-form-item
						label="事故类型:"
						prop="accident_type"
					>
						<el-select
							v-model="modifyForm.accident_type"
							placeholder="请选择事故类型"
							clearable
							v-if="canModify"
						>
							<el-option
								v-for="item in typeList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
						<p
							class="details"
							v-else
						>{{ modifyForm.accident_type | filterType }}</p>
					</el-form-item>
					<el-form-item
						label="标题:"
						prop="title"
					>
						<el-input
							v-model="modifyForm.title"
							placeholder="请输入标题"
							clearable
							v-if="canModify"
						></el-input>
						<p
							class="details"
							v-else
						>{{ modifyForm.title }}</p>
					</el-form-item>
					<el-form-item
						label="情况说明:"
						prop="condition_fix"
					>
						<el-input
							v-model="modifyForm.condition_fix"
							placeholder="请输入情况说明"
							clearable
							type="textarea"
							v-if="canModify"
							:autosize="{ minRows: 3, maxRows: 5}"
							:resize="'none'"
						></el-input>
						<p
							class="details"
							v-else
						>{{ modifyForm.condition_fix }}</p>
					</el-form-item>
					<el-form-item
						label="处置措施:"
						prop="solution"
					>
						<el-input
							v-model="modifyForm.solution"
							placeholder="请输入处置措施"
							clearable
							v-if="canModify"
							type="textarea"
							:autosize="{ minRows: 3, maxRows: 5}"
							:resize="'none'"
						></el-input>
						<p
							class="details"
							v-else
						>{{ modifyForm.solution }}</p>
					</el-form-item>
					<el-form-item
						label="上级领导:"
						prop="uplevel_leader"
					>
						<el-select
							v-model="modifyForm.uplevel_leader"
							placeholder="请选择上级领导"
							clearable
							v-if="canModify"
							filterable
							multiple
						>
							<el-option
								v-for="item in leaderList"
								:key="item.user_id"
								:label="item.name"
								:value="item.user_id"
							></el-option>
						</el-select>
						<p
							class="details"
							v-else
						>{{ leaderUsersText }}</p>
					</el-form-item>
					<el-form-item label="抄送人:">
						<el-select
							v-model="modifyForm.copy_user"
							placeholder="请选择抄送人"
							clearable
							v-if="canModify"
							filterable
							multiple
						>
							<el-option
								v-for="item in userList"
								:key="item.user_id"
								:label="item.name"
								:value="item.user_id"
							></el-option>
						</el-select>
						<p
							class="details"
							v-else
						>{{ copyUsersText }}</p>
					</el-form-item>
					<!-- 附件 -->
					<CommonTitle :title="'附件'"></CommonTitle>
					<fileUpload
						:disabled="!canModify"
						:files="modifyForm.files"
						@extra-files="extraFiles" 
					></fileUpload>
					<!-- 后续处理 -->
					<template v-if="showFollows">
						<CommonTitle :title="'事故后续处理'"></CommonTitle>
						<el-table
							ref="singleTable"
							:data="modifyForm.next_handle"
							center
							border
						>
							<el-table-column
								label="序号"
								align="center"
								type="index"
								width="50"
							>
							</el-table-column>
							<el-table-column
								label="内容"
								align="center"
								prop="follow"
							>
							</el-table-column>
							<el-table-column
								label="填报时间"
								align="120"
								prop="date"
							>
								<template slot-scope="{row}">
									{{ row.date | filterDate }}
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-form>
			</article>
			<span
				slot="footer"
				class="dialog-footer"
			>
				<el-button @click="onClose">
					{{ modifyConfig.type === 'dataInfo' ? '关闭' : '取消' }}
				</el-button>
				<template v-if="modifyConfig.type !== 'dataInfo'">
					<el-button
						type="primary"
						@click="onConfirm"
					>提 交</el-button>
					<el-button
						type="primary"
						@click="onFollowUp"
						v-if="this.modifyConfig.type === 'updateData'"
					>后续处理</el-button>
				</template>
			</span>
		</el-dialog>
		<!-- 事故后续处理 -->
		<FollowUpDialog
			v-model="showFollow"
			@submitFollow="onSubmitFollow"
		></FollowUpDialog>
	</div>
</template>

<script>
import {
	Dialog,
	Form,
	FormItem,
	Input,
	Option,
	Select,
	Table,
	TableColumn
} from 'element-ui';
import CommonTitle from './CommonTitle';
import fileUpload from '@/components/fileUpload/newFiles/index'
import FollowUpDialog from './FollowUpDialog';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { typeList } from '../config';

export default {
	name: 'ReportDialog',
	props: {
		// 显示弹窗
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		// 编辑配置
		modifyConfig: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 监理、抄送人列表
		userList: {
			type: Array,
			default: () => [],
			required: true
		},
		// 上级领导
		leaderList: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	components: {
		'el-form': Form,
		'el-form-item': FormItem,
		'el-select': Select,
		'el-option': Option,
		'el-input': Input,
		'el-dialog': Dialog,
		'el-table': Table,
		'el-table-column': TableColumn,
		CommonTitle,
		fileUpload,
		FollowUpDialog
	},
	data() {
		return {
			// 编辑数据
			modifyForm: {
				accident_type: '',
				title: '',
				condition_fix: '',
				solution: '',
				uplevel_leader: '',
				copy_user: '',
				files: [],
				next_handle: []
			},
			// 验证
			rules: {
				accident_type: [
					{ required: true, message: '请选择事故类型', trigger: 'change' }
				],
				title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
				condition_fix: [
					{ required: true, message: '请输入情况说明', trigger: 'blur' }
				],
				solution: [
					{ required: true, message: '请请输入处置措施', trigger: 'blur' }
				],
				uplevel_leader: [
					{ required: true, message: '请选择上级领导', trigger: 'change' }
				]
			},
			// 类型
			typeList: [
				{
					label: '质量',
					value: 'quality'
				},
				{
					label: '安全',
					value: 'safety'
				}
			],
			// 抄送人
			ccList: [],
			// 后续处理
			showFollow: false,
			copyUsersText: '',
			leaderUsersText: ''
		};
	},
	computed: {
		// 标题
		dialogTitle() {
			const { type = 'create' } = this.modifyConfig;
			if (type === 'updateData') {
				return '编辑事故上报';
			}
			if (type === 'dataInfo') {
				return '事故上报详情';
			}
			return '新增事故上报';
		},
		// 编辑类型
		canModify() {
			return this.modifyConfig.type !== 'dataInfo';
		},
		// 是否显示后续处理
		showFollows() {
			return (
				this.modifyConfig.type !== 'createData' &&
				this.modifyForm.next_handle &&
				Array.isArray(this.modifyForm.next_handle) &&
				this.modifyForm.next_handle.length
			);
		}
	},
	filters: {
		// 日期格式化
		filterDate(val) {
			const dateObj = new Date(val);
			const year = dateObj.getFullYear();
			let month = dateObj.getMonth() + 1;
			if (month < 10) {
				month = `0${month}`;
			}
			let day = dateObj.getDate();
			if (day < 10) {
				day = `0${day}`;
			}
			return `${year}-${month}-${day}`;
		},
		// 类型
		filterType(val) {
			if (!val || !typeList.find((ele) => ele.value === val)) return val;
			return typeList.find((ele) => ele.value === val).label;
		}
	},
	created() {
		const { type, data } = this.modifyConfig;
		if (type === 'updateData' || type === 'dataInfo') {
			const copy_user = data.copy_user || [];
			const uplevel_leader = data.uplevel_leader || [];
			this.modifyForm = {
				...data,
				files: data.files || [],
				next_handle: data.next_handle || [],
				copy_user: copy_user.map((ele) => {
					return ele.user_id;
				}),
				uplevel_leader: uplevel_leader.map((ele) => {
					return ele.user_id;
				})
			};
			if (type === 'dataInfo') {
				this.rules = {};
				console.log(this.leaderList, 'leaderList');
				this.copyUsersText = this.getNames(
					this.userList,
					this.modifyForm.copy_user
				);
				this.leaderUsersText = this.getNames(
					this.leaderList,
					this.modifyForm.uplevel_leader
				);
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.modifyForm.resetFields();
		});
	},
	methods: {
		/**
		 * @desc: 后续处理
		 */
		onFollowUp() {
			this.showFollow = true;
		},
		/**
		 * @desc: 确认
		 */
		onConfirm() {
			this.$refs.modifyForm.validate(valid => {
				if (!valid) return false;
				// 格式化人员
				// 领导
				const uplevel_leader = [];
				this.modifyForm.uplevel_leader.forEach((leaderId) => {
					if (this.leaderList.find((ele) => ele.user_id === leaderId)) {
						uplevel_leader.push(this.leaderList.find((ele) => ele.user_id === leaderId));
					}
				});
				// 抄送
				const copy_user = [];
				this.modifyForm.copy_user.forEach((copyId) => {
					if (this.userList.find((ele) => ele.user_id === copyId)) {
						copy_user.push(this.userList.find((ele) => ele.user_id === copyId));
					}
				});
				dataInterface({
					__method_name__: this.modifyConfig.type,
					data_id: this.modifyConfig.type === 'updateData' ? this.modifyForm.id : '',
					object_uuid: 'object6172917316e5a', // 事故上报
					// 字段
					...this.modifyForm,
					copy_user: JSON.stringify(copy_user || []),
					uplevel_leader: JSON.stringify(uplevel_leader || []),
					files: this.modifyForm.files || [], // 附件
					next_handle: JSON.stringify(this.modifyForm.next_handle || []) // 后续处理
				})
					.then((res) => {
						if (res.status === 200) {
							this.$message.success(this.modifyConfig.type === 'createData' ? '新增成功!' : '编辑成功!');
							this.onClose();
							eventBus.$emit('updateAccidentList');
						}
					})
					.catch((err) => {
						console.log(err, '----错误');
						this.$message.error(this.modifyConfig.type === 'createData'? '新增失败!' : '编辑失败!');
					});
			});
		},
		/**
		 * @desc: 获取详情
		 * @param {Boolean} justNext
		 */
		getInfo(justNext) {
			this.$loading();
			dataInterface({
				__method_name__: 'dataInfo',
				data_id: this.modifyConfig.data.id,
				object_uuid: 'object6172917316e5a',
				transcode: 0
			})
				.then((res) => {
					if (res.status === 200) {
						if (justNext) {
							this.modifyForm.next_handle = res.data.data.next_handle || [];
						} else {
							this.modifyForm = {
								...res.data.data,
								files: res.data.data.files || [],
								next_handle: res.data.data.next_handle || []
							};
						}
					}
					this.$loading().close();
				})
				.catch((err) => {
					console.log(err);
					this.$loading().close();
				});
		},
		/**
		 * @desc: 关闭
		 */
		onClose() {
			this.$emit('input', false);
		},
		/**
		 * @desc: 解析JSON字符串 数组（后端修改，废弃此方法使用）
		 * @param {String} val 需解析的字符串
		 * @return {Array}
		 */
		// parseJsonString(val) {
		// 	if (!val || typeof val !== 'string') return [];
		// 	try {
		// 		return JSON.parse(val);
		// 	} catch (e) {
		// 		console.log(e);
		// 		return [];
		// 	}
		// },
		/**
		 * @desc: 提交后续处理
		 * @param {*}
		 * @return {*}
		 */
		onSubmitFollow(follow) {
			this.$loading();
			const followList = this.modifyForm.next_handle || [];
			followList.push(follow);
			dataInterface({
				__method_name__: 'updateData',
				data_id: this.modifyForm.id,
				object_uuid: 'object6172917316e5a', // 事故上报
				field6172951cb79aa: JSON.stringify(followList) // 后续处理
			})
				.then((res) => {
					if (res.status === 200) {
						this.$message.success('处理成功!');
						this.showFollow = false;
						// 获取详情
						this.getInfo(true);
						this.$loading().close();
					}
				})
				.catch((err) => {
					console.log(err);
					this.$message.success('处理失败!');
					this.$loading().close();
				});
		},
		/**
		 * @desc: 获取名称
		 * @param {Array} userList 用户列表
		 * @param {Array} idList id列表
		 * @return {String} 遍历出的名称结果
		 */
		getNames(userList, idList) {
			let names = [];
			idList.forEach((ele) => {
				if (userList.find((user) => user.user_id === ele)) {
					names.push(userList.find((user) => user.user_id === ele).name || '');
				}
			});
			return names.join(', ');
		},
		/**
		 * @desc: 上传组件回调
		 * @param {Array} files 上传文件
		 * @return {String} 
		 */
		extraFiles(files) {
			this.modifyForm.files = files
		}
	}
};
</script>

<style lang="less" scoped>
:deep(.el-dialog)  {
	.el-dialog__body {
		padding-top: 10px;
	}
	.modify-content {
		width: 100%;
		box-sizing: border-box;
		padding: 0 20px;
		.el-input {
			width: 220px;
		}
	}
	.details {
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 2px;
	}
	.el-form-item {
		.el-form-item__label {
			text-align: center;
		}
	}
}
</style>