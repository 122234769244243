/*
 * @Description: 配置文件
 * @Author: luocheng
 * @Date: 2021-10-26 19:49:29
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-26 19:49:30
 */
export const typeList = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '质量',
    value: 'quality'
  },
  {
    label: '安全',
    value: 'safety'
  }
];