<!--
 * @Description: 内容
 * @Author: luocheng
 * @Date: 2021-10-26 18:08:49
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-11 10:53:43
-->
<template>
	<div
		class="report-content"
		ref="reportContent"
	>
		<el-table
			ref="singleTable"
			:data="tableData"
			highlight-current-row
			center
			border
			:max-height="maxHeight"
			v-loading="loading"
		>
			<el-table-column
				label="序号"
				align="center"
				type="index"
				width="50"
			>
			</el-table-column>
			<el-table-column
				align="center"
				property="created_at"
				label="填报日期"
				width="140px"
			>
			</el-table-column>
			<!-- 项目之外版本 code 改为 create_project_code -->
			<el-table-column
				v-if="archiType !== 'project'"
				align="center"
				property="create_project_name"
				label="项目名称"
				width="140px"
			>
			</el-table-column>
			<el-table-column
				align="center"
				property="user_name"
				label="汇报人"
				width="115px"
			>
			</el-table-column>
			<el-table-column
				align="center"
				property="accident_type"
				label="事故类型"
				width="115px"
			>
				<template slot-scope="{row}">
					{{ row.accident_type | filterType }}
				</template>
			</el-table-column>
			<el-table-column
				align="center"
				property="title"
				label="标题"
			>
			</el-table-column>
			<el-table-column
				align="center"
				property="date"
				label="操作"
				width="120px"
			>
				<template slot-scope="{row}">
					<el-button
						type="text"
						@click="onDetails(row)"
					>详情</el-button>
					<template v-if="archiType === 'project'">
						<el-button
						type="text"
						style="color: #E6A23C"
						@click="onModify(row)"
					>编辑</el-button>
					<el-button
						type="text"
						style="color: #F56C6C"
						@click="onRemove(row)"
					>删除</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			v-if="pager.total"
			@size-change="onSize"
			@current-change="onPage"
			:current-page="pager.current_page"
			:page-sizes="[15, 30, 50, 100]"
			:page-size="pager.size"
			layout="total, sizes, prev, pager, next, jumper"
			size="small"
			:total="pager.total"
		>
		</el-pagination>
	</div>
</template>

<script>
import { Pagination, Table, TableColumn } from 'element-ui';
import { dataInterface } from '@/apis/data/index';
import { typeList } from '../config';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'ReportContent',
	components: {
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-pagination': Pagination
	},
	props: {
		// 搜索配置
		searchData: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			// 数据
			tableData: [],
			// 分页
			pager: {
				current_page: 1,
				size: 15,
				total: 0
			},
			// 高度
			maxHeight: 500,
			loading: false
		};
	},
	computed: {
		// 当前架构类型
		archiType() {
			return this.$GetTargetArchi('archiType');
		}
	},
	created() {
		this.getList();
	},
	watch: {
		// 搜索数据
		searchData: {
			handler() {
				// 搜索
				if (
					!this.searchData ||
					!this.searchData.type ||
					!this.searchData.filterForm ||
					typeof this.searchData !== 'object'
				) {
					return false;
				}
				this.onPage(1);
			},
			deep: true
		}
	},
	filters: {
		// 类型
		filterType(val) {
			if (!val || !typeList.find((ele) => ele.value === val)) return val;
			return typeList.find((ele) => ele.value === val).label;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.setTableSize();
			eventBus.$on('updateAccidentList', () => {
				this.onPage(1);
			});
		});
		window.onresize = () => {
			this.setTableSize();
		};
	},
	methods: {
		/**
		 * @desc: 获取列表
		 */
		getList() {
			this.loading = true;
			const search = [];
			const { filterForm = {} } = this.searchData;
			search.push({
				field_uuid: 'field6178f0b614230',
				ruleType: 'like',
				value: filterForm.accident_type || ''
			});
			// 项目名称待补充
			search.push({
				field_uuid: 'field617293289e023',
				ruleType: 'like',
				value: filterForm.project_name || ''
			})
			search.push({
				field_uuid: 'field6172930b26df5',
				ruleType: 'gt',
				value: filterForm.uplevel_leader || ''
			})
			search.push({
				field_uuid: 'field6172917326e99',
				ruleType: 'like',
				value: filterForm.created_at || ''
			})
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object6172917316e5a',
        // 公司端和项目端使用两个视图 以过滤数据
				view_uuid: this.archiType == 'project' ? 'view6180a2c7bd64c' : 'view6180eb2974d9b',
				search,
				page: this.pager.current_page,
				size: this.pager.size,
				transcode: 0
			})
				.then((res) => {
					this.tableData = [];
					if (
						!res ||
						res.status !== 200 ||
						!res.data ||
						!res.data.code ||
						!res.data.data
					)
						return;
					const result = res.data.data;
					this.tableData = result.data || [];
					this.pager = {
						current_page: result.current_page || 1,
						size: result.per_page || 15,
						total: result.total || 0
					};
					this.loading = false;
				})
				.catch((err) => {
					console.log(err, '222222');
					this.loading = false;
				});
		},
		/**
		 * @desc: 详情
		 * @param {Object} row 行数据
		 */
		onDetails(data) {
			this.$emit('modifyAccident', {
				type: 'dataInfo',
				data
			});
		},
		/**
		 * @desc: 编辑
		 * @param {Object} data 行数据
		 */
		onModify(data) {
			this.$emit('modifyAccident', {
				type: 'updateData',
				data
			});
		},
		/**
		 * @desc: 删除
		 * @param {Object} data 行数据
		 */
		onRemove(data) {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			})
				.then(() => {
					this.$loading();
					dataInterface({
						__method_name__: 'deleteData',
						object_uuid: 'object6172917316e5a',
						data_id: data.id
					})
						.then(() => {
							this.$loading().close();
							// 获取数据
							this.onPage(1);
						})
						.catch(() => {
							this.$loading().close();
						});
				})
				.catch(() => {});
		},
		/**
		 * @desc: 切换siaze
		 * @param {Number} size 每页条数
		 */
		onSize(size) {
			this.pager.size = size;
			this.pager.current_page = 1;
			this.getList();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page 页码
		 */
		onPage(page) {
			this.pager.current_page = page;
			this.getList();
		},
		/**
		 * @desc: 计算表格尺寸
		 */
		setTableSize() {
			this.$nextTick(() => {
				const dom = this.$refs.reportContent;
				if (dom) {
					this.maxHeight = dom.offsetHeight - 40;
				}
			});
		}
	},
	beforeDestroy() {
		eventBus.$off('updateAccidentList');
	}
};
</script>

<style lang="less" scoped>
.report-content {
	width: 100%;
	height: 100%;
	.el-table {
		// border: 1px solid #f2f3f5;
		// border-bottom: none;
		// border-radius: 4px;
	}
	.el-pagination {
		box-sizing: border-box;
		padding-top: 10px;
	}
}
</style>