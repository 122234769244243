<!--
 * @Description: title
 * @Author: luocheng
 * @Date: 2021-10-26 19:41:32
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-26 20:14:10
-->
<template>
  <div class="common-title">
    <span class="line"></span>
    <p class="title" :style="styleObj">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'CommonTitle',
  props: {
    // 标题
    title: {
      type: String,
      required: true,
      default: ''
    },
    // 样式
    styleObj: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.common-title{
  box-sizing: border-box;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  min-height: 20px;
  .line{
    margin-top: 2px;
    width: 2px;
    height: 15px;
    border-radius: 1px;
    background: @theme;
  }
  .title{
    flex: 1;
    line-height: 20px;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 14px;
    color: #333;
  }
}
</style>