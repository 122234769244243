<!--
 * @Description: 筛选
 * @Author: luocheng
 * @Date: 2021-10-26 17:55:06
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-15 17:15:18
-->
<template>
	<el-form
		v-model="filterForm"
		inline
	>
		<el-form-item label="筛选类型">
			<el-select
				v-model="filterForm.accident_type"
				placeholder="请选择筛选类型"
				clearable
			>
				<el-option
					v-for="item in typeList"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				></el-option>
			</el-select>
		</el-form-item>
		<el-form-item
			label="项目名称"
			v-if="archiType !== 'project'"
		>
			<el-input
				v-model="filterForm.project_name"
				placeholder="请输入项目名称"
				clearable
			></el-input>
		</el-form-item>
		<el-form-item
			label="汇报人"
			v-if="archiType === 'project'"
		>
			<el-select
				v-model="filterForm.uplevel_leader"
				placeholder="请选择汇报人"
				clearable
			>
				<el-option
					v-for="user in userList"
					:key="user.user_id"
					:label="user.name"
					:value="user.user_id"
					filterable
				></el-option>
			</el-select>
		</el-form-item>
		<!-- <el-form-item label="参与人">
			<el-select
				v-model="filterForm.copy_user"
				placeholder="请选择参与人"
				clearable
			>
				<el-option
					label="汇报1"
					value="111"
				></el-option>
			</el-select>
		</el-form-item> -->
		<!-- <el-form-item label="状态">
      <el-select v-model="filterForm.status" placeholder="请选择状态" clearable>
        <option label="已读" value="read"></option>
        <option label="未读" value="unread"></option>
      </el-select>
    </el-form-item> -->
		<el-form-item label="日期">
			<el-date-picker
				v-model="filterForm.created_at"
				placeholder="请选择日期"
				value-format="yyyy-MM-dd"
				clearable
				type="date"
			>
			</el-date-picker>
		</el-form-item>
		<el-form-item>
			<el-button
				type="primary"
				@click="onSearch('filter')"
			>查询</el-button>
			<el-button @click="onSearch('reset')">重置</el-button>
			<el-button
				v-if="archiType === 'project'"
				type="success"
				prefix="el-icon-plus"
				@click="onPlus"
			>新增</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
import { DatePicker, Form, FormItem, Option, Select } from 'element-ui';
import { typeList } from '../config';

export default {
	name: 'AccidentFilter',
	components: {
		'el-form': Form,
		'el-form-item': FormItem,
		'el-select': Select,
		'el-option': Option,
		'el-date-picker': DatePicker
	},
	props: {
		// 上报人列表 监理
		userList: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			// 筛选
			filterForm: {
				accident_type: '', // 类型
				project_name: '', // 项目名称
				user_id: '', // 汇报人
				copy_user: '', // 参与人
				created_at: '' // 日期,
			},
			// 类型
			typeList
		};
	},
	computed: {
		// 当前架构类型
		archiType() {
			return this.$GetTargetArchi('archiType');
		}
	},
	methods: {
		/**
		 * @desc: 搜索
		 * @param {String} type 类型 filter 搜索 reset 重置
		 */
		onSearch(type) {
			if (type === 'reset') {
				this.filterForm = {
					accident_type: '', // 类型
					project_name: '',
					user_id: '', // 汇报人
					copy_user: '', // 参与人
					created_at: '' // 日期
				};
			}
			this.$emit('search', {
				type,
				filterForm: JSON.parse(JSON.stringify(this.filterForm))
			});
		},
		/**
		 * @desc: 增加
		 */
		onPlus() {
			this.$emit('modifyAccident', {
				type: 'createData'
			});
		}
	}
};
</script>

<style lang="less" scoped>
.el-form {
	text-align: left;
	.el-form-item {
		margin-bottom: 10px;
	}
}
</style>